<template>
	<v-row no-gutters justify="center">
		<v-col cols="12">
			<v-dialog v-model="dialog.status" max-width="575" persistent>
				<CardDialog :dialog="dialog" />
			</v-dialog>
			<v-card ref="form" :class="dialogCliente ? 'rounded-0': ''">
				<v-card-title>
					<v-row align="center">
						<v-col class="text-truncate">Cadastro de Cliente</v-col>
						<v-col v-if="dialogCliente" cols="auto" class="text-end">
							<v-btn color="primary" text fab @click="$emit('fechar')">
								<v-icon size="36">mdi-close-thick</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-divider />
				<v-card-text class="pt-1">
					<v-row>
						<v-col class="pb-1" cols="12" xl="12" lg="12" md="12" sm="12">
							<fieldset class="px-4 pb-3">
								<legend>Cliente / Fornecedor</legend>
								<v-row class="pt-1">
									<v-col cols="12" xl="6" lg="6" md="6" class="mt-4 py-0">
										<!--tipoPessoa-->
										<v-radio-group class="mt-0 mb-3" hide-details v-model="dados.tipoPessoa" row>
											<v-col class="px-0 py-1" cols="6" xl="6" lg="6" md="6">
												<v-radio label="Pessoa Fisíca" value="F"></v-radio>
											</v-col>
											<v-col class="px-0 py-1" cols="6">
												<v-radio label="Pessoa Jurídica" value="J"></v-radio>
											</v-col>
										</v-radio-group>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" class="py-0">
										<!--apelidoFantasia-->
										<div v-if="dados.tipoPessoa == 'F'" class="text-body-1 font-weight-bold">Apelido</div>
										<div v-if="dados.tipoPessoa == 'J'" class="text-body-1 font-weight-bold">Nome Fantasia</div>
										<v-text-field class="mb-n3" v-model="dados.apelidoFantasia" ref="apelido" outlined dense></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" class="py-0">
										<!--nomeRazao-->
										<div v-if="dados.tipoPessoa == 'F'" class="text-body-1 font-weight-bold">*Nome</div>
										<div v-if="dados.tipoPessoa == 'J'" class="text-body-1 font-weight-bold">*Razão Social</div>
										<v-text-field class="mb-n3" ref="nome" v-model="dados.nomeRazao" outlined dense required></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" class="py-0" v-if="dados.tipoPessoa == 'F'">
										<!--cpf-->
										<div class="text-body-1 font-weight-bold">*CPF</div>
										<v-text-field
											class="mb-n3"
											ref="CPF"
											v-model="dados.cnpjcpf"
											v-mask="'###.###.###-##'"
											outlined
											dense
											required
										></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" class="py-0" v-if="dados.tipoPessoa == 'J'">
										<!--cnpj-->
										<div class="text-body-1 font-weight-bold">*CNPJ</div>
										<v-text-field
											class="mb-n3"
											ref="CPF"
											v-model="dados.cnpjcpf"
											v-mask="'##.###.###/####-##'"
											outlined
											dense
											required
										></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" class="py-0">
										<!--atividade-->
										<div class="text-body-1 font-weight-bold">*Atividade</div>
										<v-autocomplete
											class="mb-n3"
											ref="cidade"
											v-model="dados.idatividade"
											:items="atividades"
											outlined
											dense
											placeholder="Selecione uma atividade"
											required
										></v-autocomplete>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" class="py-0">
										<!--inscricaoEstadual-->
										<div class="text-body-1 font-weight-bold">
											*Inscrição estadual
											<v-tooltip color="primary" bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-bind="attrs"
														v-on="on"
														small
														color="light-blue"
														class="mt-n1"
													>mdi-information-outline</v-icon>
												</template>
												<span>Caso cliente não possua, informar isento</span>
											</v-tooltip>
										</div>
										<v-text-field class="mb-n8" v-model="dados.inscricaoEstadual" outlined dense required></v-text-field>
										<v-row>
											<v-col cols="6" class="mb-3">
												<v-checkbox
													v-model="dados.flagiebaixada"
													label="IE Baixada"
													hide-details
													class="ma-0"
												></v-checkbox>
											</v-col>
											<v-col cols="6">
												<v-checkbox
													v-model="dados.flagmei"
													label="MEI"
													hide-details
													class="ma-0"
												></v-checkbox>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</fieldset>
						</v-col>
						<v-col class="py-1" cols="12">
							<fieldset class="px-4 pb-3">
								<legend>Contatos</legend>
								<v-row class="py-3">
									<v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
										<div class="text-body-1 font-weight-bold">Contato Principal</div>
										<v-text-field class="mb-n3" ref="CPF" v-model="dados.contato" outlined dense required></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
										<div class="text-body-1 font-weight-bold">Contato Secundário</div>
										<v-text-field class="mb-n3" ref="CPF" v-model="dados.contato2" outlined dense required></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
										<div class="text-body-1 font-weight-bold">Celular</div>
										<v-text-field
											v-mask="'(##)#####-####'"
											class="mb-n3"
											ref="celular"
											v-model="dados.celular"
											outlined
											dense
											required
										></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
										<div class="text-body-1 font-weight-bold">Telefone Comercial</div>
										<v-text-field
											v-mask="'(##)#####-####'"
											class="mb-n3"
											ref="CPF"
											v-model="dados.telefoneComercial"
											outlined
											dense
											required
										></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
										<div class="text-body-1 font-weight-bold">E-mail</div>
										<v-text-field class="mb-n3" ref="CPF" v-model="dados.email" outlined dense required></v-text-field>
									</v-col>
									<v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
										<div class="text-body-1 font-weight-bold">E-mail Financeiro</div>
										<v-text-field
											class="mb-n3"
											ref="CPF"
											v-model="dados.emailfinanceiro"
											outlined
											dense
											required
										></v-text-field>
									</v-col>
								</v-row>
							</fieldset>
						</v-col>
						<v-col class="py-1" cols="12">
							<fieldset class="px-4 pb-3">
								<legend>Localização</legend>
								<v-row class="py-3">
									<v-col cols="12" md="6" class="py-0">
										<div class="text-body-1 font-weight-bold">*CEP</div>
										<v-text-field
											class="mb-n3"
											v-model="dados.cep"
											outlined
											required
											dense
											v-mask="'#####-###'"
											:loading="carregando"
											@change="listarCeps()"
										></v-text-field>
									</v-col>
									<v-col cols="6" md="6" class="py-0">
										<div class="text-body-1 font-weight-bold">*Cidade</div>
										<v-text-field
											class="mb-n3"
											ref="Cidade"
											v-model="dados.cidade"
											outlined
											dense
											required
											disabled
										></v-text-field>
									</v-col>
									<v-col cols="6" md="4" class="py-0">
										<div class="text-body-1 font-weight-bold">*Estado</div>
										<v-text-field
											class="mb-n3"
											ref="Estado"
											v-model="dados.estado"
											outlined
											dense
											required
											disabled
										></v-text-field>
									</v-col>
									<v-col cols="8" md="6" class="py-0">
										<div class="text-body-1 font-weight-bold">*Endereço</div>
										<v-text-field
											class="mb-n3"
											ref="Endereco"
											v-model="dados.endereco"
											outlined
											dense
											required
										></v-text-field>
									</v-col>
									<v-col cols="4" md="2" class="py-0">
										<div class="text-body-1 font-weight-bold">*Número</div>
										<v-text-field class="mb-n3" ref="Numero" v-model="dados.numero" outlined dense required></v-text-field>
									</v-col>
									<v-col cols="6" md="4" class="py-0">
										<div class="text-body-1 font-weight-bold">*Bairro</div>
										<v-text-field class="mb-n3" ref="Bairro" v-model="dados.bairro" outlined dense required></v-text-field>
									</v-col>
									<v-col cols="6" md="3" class="py-0">
										<div class="text-body-1 font-weight-bold">Região</div>
										<v-autocomplete
											class="mb-n3"
											v-model="dados.idregiao"
											:items="regioes"
											:loading="carregando"
											item-text="regiao"
											item-value="idregiao"
											outlined
											dense
											required
										></v-autocomplete>
									</v-col>
									<v-col cols="12" md="5" class="py-0">
										<div class="text-body-1 font-weight-bold">Complemento</div>
										<v-text-field
											class="mb-n3"
											ref="Complemento"
											v-model="dados.complemento"
											outlined
											dense
											required
										></v-text-field>
									</v-col>
									<v-col cols="12" class="py-0">
										<div class="text-body-1 font-weight-bold">Obeservação</div>
										<v-textarea class="mb-n3" v-model="dados.observacao" outlined dense></v-textarea>
									</v-col>
								</v-row>
							</fieldset>
						</v-col>
					</v-row>

					<v-alert class="mb-n3 mt-4" type="error" v-if="erro" v-html="erro" />
				</v-card-text>
				<v-card-actions class="pt-1">
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="cadastrarCliente()" class="mr-2">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "ClienteNovo",
	components: { CardDialog },
	props: { dialogCliente: { type: Boolean, default: false } },
	data: () => ({
		dados: {
			flagiebaixada: false,
			flagmei: false,
			tipoPessoa: "F",
			cidade: "",
			estado: "",
      idatividade: '39',
      inscricaoEstadual: '',
		},
		ceps: { lista: [] },
		atividades: [],
		regioes: [],

		dialog: { status: false },
		carregando: false,
		busca: {},
		keydown: false,
		erro: null,
	}),
	methods: {
		cadastrarCliente() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}cliente/cadastrar`, {
          tipopessoa: this.dados.tipoPessoa || 'F',
          nomeRazao: this.dados.nomeRazao
              ? this.dados.nomeRazao.toUpperCase()
              : "",
          apelidofantasia: this.dados.apelidofantasia.toUpperCase() || '',
          cnpjcpf: this.dados.cnpjcpf
              ? this.dados.cnpjcpf.replace(/[^0-9]/g, "")
              : null,
          idatividade: parseInt(this.dados.idatividade) || 39,
          inscricaoestadual: this.dados.inscricaoEstadual || 'ISENTO',
          flagiebaixada: this.dados.flagiebaixada ? "T" : "F",
          flagmei: this.dados.flagmei ? "T" : "F",
          contato: this.dados.contato || '',
          contato2: this.dados.contato2 || '',
          telefoneComercial: this.dados.telefoneComercial
              ? this.dados.telefoneComercial.replace(/[^0-9]/g, "")
              : null,
          celular: this.dados.celular
              ? this.dados.celular.replace(/[^0-9]/g, "")
              : null,
          email: this.dados.email || '',
          emailFinanceiro: this.dados.emailfinanceiro || '',
          cep: parseInt(
              this.dados.cep ? this.dados.cep.replace("-", "") : ""
          ),
          idCidade: parseInt(this.dados.idcidade) || 56510,
          endereco: this.dados.endereco
              ? this.dados.endereco.toUpperCase()
              : "",
          numero: this.dados.numero || '000',
          bairro: this.dados.bairro.toUpperCase() || '',
          idregiao: this.dados.idregiao || 31,
          complemento: this.dados.complemento.toUpperCase() || '',
          observacao: this.dados.observacao.toUpperCase() || '',



				})
				.then((res) => {
					this.erro = null;
          this.limparDados();
					this.carregando = false;
					if (typeof res.data === "number") {
						if (this.dialogCliente) {
							this.$emit("fechar");
						} else {
							return this.$router.push(
								`/cliente/get/${res.data}`
							);
						}
					}
				})
				.catch((r) => {
					//console.log(r.response.data.erro)
					this.erro = r.response.data.erro.replaceAll(";", "<br/>");
          this.limparDados();
					this.carregando = false;
					// this.dialog.status = true;
					// this.dialog.title = "Erro";
					// this.dialog.msg = r.response.data.erro.replaceAll(';', '<br/>')
					// this.dialog.icon = "mdi-alert-circle-outline";
					// this.dialog.iconColor = "red";
				});
		},
		listarAtividades() {
			return axios
				.post(`${this.backendUrl}cliente/atividade/listar`)
				.then((res) => {
					this.atividades = res.data.map((v) => {
						return {
							value: v.idatividade,
							text: `${v.idatividade} - ${v.atividade}`,
						};
					});
				});
		},
    limparDados(){
      this.dados.nomeRazao = '';
      this.dados.apelidofantasia = '';
      this.dados.cnpjcpf = '';
      this.dados.idatividade = '';
      this.dados.inscricaoEstadual = '';
      this.dados.flagiebaixada = '';
      this.dados.flagmei = false;
      this.dados.contato = '';
      this.dados.contato2 = '';
      this.dados.telefoneComercial = '';
      this.dados.celular = '';
      this.dados.email = '';
      this.dados.emailfinanceiro = '';
      this.dados.cep = '';
      this.dados.idcidade = null;
      this.dados.endereco = '';
      this.dados.numero = '';
      this.dados.bairro = '';
      this.dados.idregiao = '';
      this.dados.complemento = '';
      this.dados.observacao = '';
    },
		listarRegioes() {
			return axios
				.post(`${this.backendUrl}regioes/listar`)
				.then((res) => {
					this.regioes = res.data;
				});
		},
		listarCeps() {
			this.ceps.lista = [];
			return axios
				.post(`${this.backendUrl}endereco/cep/get`, {
					cep: this.dados.cep.replace("-", ""),
					limit: 99999,
					offset: 0,
				})
				.then((res) => {
					this.dados.cidade = res.data.cidade.trim();
					this.dados.bairro = res.data.bairro.trim();
					this.dados.estado = res.data.estado.trim();
					this.dados.endereco = res.data.endereco.trim();
					this.dados.idcidade = res.data.idcidade;
					if (res.data.lista != null) {
						this.ceps = res.data;
					}
				})
				.catch(() => {
					this.dados.cidade = "";
					this.dados.bairro = "";
					this.dados.estado = "";
					this.dados.endereco = "";
				});
		},
		async init() {
			await this.listarAtividades();
			await this.listarRegioes();
		},
	},
	watch: {
		"dados.tipoPessoa"() {
			if (this.dados.tipoPessoa == "F") {
				this.dados.flagmei = false;
			}
		},
	},
	computed: {
		...mapState(["pgLimit", "backendUrl"]),
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
fieldset {
	padding: 10px;
	border: solid rgba(37, 37, 37, 0.429) 1px;
	border-radius: 4px;
}
fieldset legend {
	padding: 0px 5px;
}
</style>  